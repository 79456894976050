<template>
    <div>
        <div class="d-flex pb-2">
            <div>
                <v-icon @click="backToList" class="text-black" style="cursor: pointer;">mdi-arrow-left</v-icon>
            </div>
            <div class="align-center">
                <p style="font-size: 18px;" class="font-weight-semibold text-black ml-5">
                    {{ $t('View Admins Details') }}
                </p>
            </div>
        </div>

        <v-divider></v-divider>

        <v-card class="mt-8">
            <v-card-title class="font-weight-semibold  pt-8">
                <div class="color text-green">{{ $t('Admin Details') }}</div>
            </v-card-title>
            <v-row class="pl-6">
                <v-col cols="12" lg="2" md="2" sm="3" v-for="item in user" :key="item.id">
                    <div class="color text-green text-caption">{{ $t(item.id) }}</div>
                    <p class="text-black text-caption font-weight-bold">{{ item.text }}</p>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import { mdiEyeOutline } from '@mdi/js';

import moment from 'moment';
// composition function
import router from '@/router';
import userServices from '@/services/user-services';
import useUsersList from './useUsersList';

export default {
    data: () => ({
        applicationList: '',
        user: [

        ],

        route: router.currentRoute.params.id,
    }),
    methods:
    {
        backToList() {
            router.push({ name: 'admin-list' })
        }
    },
    mounted() {

        userServices.getUser(this.route).then(resp => {
            if (resp.statusCode == 200) {
                this.user = [
                    { id: 'Admin ID', text: resp.data.users[0].id },
                    { id: 'Admin name', text: resp.data.users[0].first_name ? resp.data.users[0].first_name : '' +' '+ resp.data.users[0]?.last_name != "Null" ? resp.data.users[0]?.last_name: '' },
                    { id: 'Mobile No', text: resp.data.users[0].phone },
                    { id: 'Email Address', text: resp.data.users[0].email },
                    { id: 'Joining Date', text: moment(new Date(resp.data.users[0].created_at)).format('DD/MM/yyyy') }
                ]
            }

        })
    },
    setup() {
        const {
            userListTable,
            searchQuery,
            tableColumns,
            options,
            totalUserListTable,
            loading,
            selectedTableData,
            resolveRole,
        } = useUsersList()

        return {
            tableColumns,
            searchQuery,
            options,
            totalUserListTable,
            userListTable,
            loading,
            selectedTableData,
            resolveRole,

            icons: {
                mdiEyeOutline
            },
        }
    },
}
</script>

<style lang="scss" scoped>
#user-list {
    .user-list-actions {
        max-width: 7.81rem;
    }

    .user-list-search {
        max-width: 10.625rem;
        border-radius: 20px;
    }

    .user-list-status {
        max-width: 11.3rem;
    }
}
.text-black{
    color:#000;
}
.size {
    font-size: 20px;
}

.color {
    color: #00B2A9;
}

.user-search {
    max-width: 17.625rem;
    border-radius: 20px;
}

.font-size {
    font-size: 14px;
    font-weight: 400;
}

.my-data-table ::v-deep .v-data-table-header th {
    font-weight: bold;
    font-size: 14px;
    height: 40px;
}


::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td {
    font-size: 0.875rem;
    height: 3.125rem;
    border-left: 1px solid #efeded !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
    border-bottom: none !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    color: #131C24 !important;
    background: rgba(165, 174, 182, 0.15) !important;

}

::v-deep .v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    border-bottom: thin solid rgba(94, 86, 105, 0.14);
    border-right: 1px solid #efeded;
}
</style>
